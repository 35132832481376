import PageWrapper from '../../components/PageWrapper';
import { Box, Typography, Container, Stack } from '@mui/material';
import telegramIcon from '../../assets/icon/tm.png';
import whatsAppIcon from '../../assets/icon/wa.png';
import MessengerButton from '../../components/messenger-button';
import { ActionText } from '../../components/action-text';

const WelcomePageView = () => {
  return (
    <PageWrapper>
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: {
            xs: '20px',
          },
          paddingX: '0',
          maxWidth: '880px',
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            gap: {
              xs: '0px',
              md: '20px',
            },
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginBottom: {
                xs: '10px',
                md: '0',
              },
            }}
          >
            <a
              href="https://missis-laser.ru"
              style={{ width: '95px', display: 'block' }}
            >
              <img
                src="../../assets/logo-color.png"
                alt="Миссис Лазер"
                style={{ width: '95px', display: 'block' }}
              />
            </a>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                color: 'text.secondary',
                maxWidth: '240px',
                wordWrap: 'break-word',
                textAlign: 'center',
                margin: '0px',
              }}
            >
              missis_laser
            </Typography>
          </Stack>

          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: '17px',
              lineHeight: '26px',
              fontWeight: 400,
              color: 'text.primary',
              maxWidth: '240px',
              wordWrap: 'break-word',
              textAlign: 'center',
              margin: '0px',
              marginTop: '0px',
            }}
          >
            Сеть клиник лазерной эпиляции и косметологии
            <Box component="span" sx={{ color: 'text.secondary' }}>
              {' '}
              «Миссис Лазер»
            </Box>
          </Typography>
        </Stack>

        <ActionText />

        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          width="100%"
          alignItems="center"
        >
          <MessengerButton
            href="https://t.me/m/P30t0RTdMThi"
            text="Написать в Telegram"
            iconSrc={telegramIcon}
            backgroundColor="#77c6e5"
            hoverColor="#6bb0d4"
            altText="Telegram Icon"
          />

          <MessengerButton
            href="https://wa.me/79315878205?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D1%85%D0%BE%D1%87%D1%83%20%D0%B2%D1%8B%D0%B8%D0%B3%D1%80%D0%B0%D1%82%D1%8C%20iPhone%2015%2C%20%D0%9F%D1%80%D0%BE%D0%BC%D0%BE%D0%BA%D0%BE%D0%B4%20%C2%AB%D1%81%D0%B5%D0%B9%D1%84%C2%BB"
            text="Написать в WhatsApp"
            iconSrc={whatsAppIcon}
            backgroundColor="#2cb742"
            hoverColor="#28a745"
            altText="WhatsApp Icon"
          />
        </Stack>
      </Container>
    </PageWrapper>
  );
};

export default WelcomePageView;
